import { Box, Dialog, Grid, Slide, Typography, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect } from "react";
import MainLogo from "../../../../assets/logo-white.png";
import NotifySong from "../../../../assets/notify.wav";
import PanelBg from "../../../../assets/panel-bg.png";
import { ICallPanelOffice } from "../../../../models";
import { Image } from "../../styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICallModalProps {
  currentPanelOffice: ICallPanelOffice | null;
  currentPatient: string;
  currentPrioritario: boolean;
  currentProfessional: string;
}

const CallPatientModal = ({
  currentPanelOffice,
  currentPatient,
  currentPrioritario,
  currentProfessional,
}: ICallModalProps) => {
  const theme = useTheme();

  useEffect(() => {
    const audio = new Audio(NotifySong);
    audio
      .play()
      .then(() => {
        console.log("Sound play");
      })
      .catch(() => {
        console.log("Sound not play");
      });
  }, [currentPanelOffice]);

  return (
    <Dialog
      fullScreen
      open={currentPanelOffice !== null}
      TransitionComponent={Transition}
      onClose={() => {}}
    >
      <Box
        sx={{
          background: `url(${PanelBg}), ${theme.palette.primary.light} center center/cover`,
          height: "100vh",
          width: "100%",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid container>
          <Grid item lg={12} display="flex" justifyContent="center" mt={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "40px",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              ATENÇÃO
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={2}>
          <Grid item lg={12} xs={12} display="flex" justifyContent="center">
            <Typography
              component="h1"
              sx={{
                fontSize: "40px",
                fontWeight: 500,
                color: theme.palette.background.default,
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
              }}
            >
              Paciente:
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12} display="flex" justifyContent="center">
            <Typography
              component="h1"
              sx={{
                fontSize:
                  currentPatient && currentPatient.length > 30
                    ? "60px"
                    : "70px",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                textAlign: "center",
              }}
            >
              {currentPatient}
            </Typography>
          </Grid>
          {currentProfessional !== "" && (
            <>
              <Grid
                item
                lg={6}
                mt={2}
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  component="h1"
                  sx={{
                    fontSize: "30px",
                    fontWeight: 500,
                    color: theme.palette.background.default,
                    textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  Profissional:
                </Typography>
                <Typography
                  component="h1"
                  sx={{
                    fontSize: "42px",
                    fontWeight: 600,
                    color: theme.palette.background.default,
                    textTransform: "uppercase",
                    textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {currentProfessional}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            item
            lg={6}
            mt={2}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "30px",
                fontWeight: 500,
                color: theme.palette.background.default,
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
              }}
            >
              Consultório:
            </Typography>
            <Typography
              component="h1"
              sx={{
                fontSize: "52px",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
              }}
            >
              {currentPanelOffice?.consultorio.nome}
            </Typography>
          </Grid>

          {/* <Grid
            item
            lg={12}
            xs={12}
            mt={1}
            display="flex"
            justifyContent="center"
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "40px",
                fontWeight: 500,
                color: theme.palette.background.default,
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
              }}
            >
              Prioridade:
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12} display="flex" justifyContent="center">
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
                textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
              }}
            >
              {currentPrioritario ? "SIM" : "NÃO"}
            </Typography>
          </Grid> */}
        </Grid>

        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={6}
        >
          <Grid item lg={2} md={12} sm={12}>
            <Image src={MainLogo} alt="Logo" />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CallPatientModal;
