import { useEffect, useRef } from "react";
import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { usePanel } from "./contexts/PanelContext";
import Loader from "./layout/Loader";
import Login from "./pages/Login";
import NewPanel from "./pages/NewPanel";
import OfficePanel from "./pages/OfficePanel";

export default function Routes() {
  const { panel, isConnected, setIsConnected } = usePanel();
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isConnected) {
      window.scrollTo({
        behavior: "auto",
        top: 0,
      });
      ref.current?.click();
      setIsConnected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <>
      {/* {panel && (
        <Dialog open={!isOpen} fullWidth>
          <DialogTitle textAlign="center">ABRIR PAINEL</DialogTitle>
          <DialogContent
            sx={{
              textAlign: "center",
            }}
          >
            Clique em abrir para acessar o painel {panel?.nome}
          </DialogContent>
          <DialogActions
            sx={{
              px: 16,
              py: 3,
            }}
          >
            <Button
              onClick={() => {
                setIsOpen(true);
                document.body.requestFullscreen();
              }}
              fullWidth
              variant="contained"
            >
              Abrir
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
      <ReactRouterRoutes>
        <Route path="/auth" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              {panel?.type === "GUICHÊ" ? <NewPanel /> : <OfficePanel />}
            </ProtectedRoute>
          }
        />
      </ReactRouterRoutes>
      <button ref={ref} id="clickMe" onClick={() => console.log("Teste")}>
        Start panel click
      </button>
    </>
  );
}

const ProtectedRoute = ({ children }: any) => {
  const { isLoading, isRegistered } = usePanel();
  const result = isRegistered();
  if (isLoading) {
    return <Loader />;
  }

  if (!result) {
    return <Navigate to={`/auth`} replace />;
  }

  return children;
};
