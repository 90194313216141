import React from 'react';
import {
  Box, Container, Grid, Typography,
} from '@mui/material';

// import { Container } from './styles';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              component="h1"
              sx={{
                fontSize: '32px',
                fontWeight: 600,
                color: (theme) => theme.palette.background.default,
              }}
            >
              AGUARDE VOCÊ SER CHAMADO NO CONSULTÓRIO
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
