import axios from 'axios';
import { io } from 'socket.io-client';

const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:8000'; // ws://apidev.maissaude.bitx.com.br

const baseURL = process.env.NODE_ENV === 'development'
? process.env.REACT_APP_DEV
: process.env.REACT_APP_PROD;

export const api = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
});

export const socket = io(socketUrl);
