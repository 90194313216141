import React from "react";
import { BrowserRouter } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import Routes from "./routes";

import { theme, themeMUI } from "./styles/theme/default";
import GlobalStyles from "./styles/global";
import { PanelProvider } from "./contexts/PanelContext";

function App() {
  return (
    <ThemeProvider theme={themeMUI}>
      <CssBaseline />
      <ThemeProviderStyled theme={theme}>
        <GlobalStyles />
        <PanelProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </PanelProvider>
      </ThemeProviderStyled>
    </ThemeProvider>
  );
}

export default App;
