import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Image, ImageSmall } from "../../styles";
import MainLogo from "../../../../assets/logo-white.png";
import { usePanel } from "../../../../contexts/PanelContext";
import Logout from "../../../../assets/logout.svg";

function Header() {
  const theme = useTheme();
  const [clock, setClock] = useState<string>("");
  const { panel, handleClosePanel } = usePanel();

  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      const hour = date.toTimeString().split(" ")[0].split(":")[0];
      const minute = date.toTimeString().split(" ")[0].split(":")[1];

      setClock(`${hour}:${minute}`);
    }, 1000);
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
        pl: 2,
        pr: 2,
      }}
    >
      {/* <Container maxWidth="xl"> */}
      <Grid container>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            <Typography
              component="h1"
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: theme.palette.background.default,
              }}
            >
              {panel?.nome}
            </Typography>
            <Tooltip title="Fechar painel">
              <IconButton onClick={() => handleClosePanel()}>
                <img src={Logout} width={24} alt="" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
          >
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <ImageSmall src="/assets/bullets.png" alt="bullets" />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={MainLogo} alt="Logo" />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              <ImageSmall src="/assets/bullets.png" alt="bullets" />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          md={4}
          xs={12}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Typography
            component="h1"
            sx={{
              fontSize: "48px",
              fontWeight: 600,
              color: theme.palette.background.default,
            }}
          >
            {clock}
          </Typography>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box>
  );
}

export default Header;
