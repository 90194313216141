import styled from '@emotion/styled';

export const Image = styled.img`
  width: 100%;
  /* height: 64px; */
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
`;

export const ImageSmall = styled.img`
  width: 80%;
  /* height: 32px; */
`;
