import { createTheme } from "@mui/material";

export const theme = {
  colors: {
    primary: {
      dark: "#148776",
      main: "#23B09B",
      light: "#60CFBE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF702A",
      light: "#FF9962",
    },
    white: {
      500: "#F6F7F7",
    },
    error: {
      main: "#FF0000",
      light: "#FF5F5F",
      dark: "#A60000",
    },
    warning: {
      main: "#FFD700",
      light: "#FFF8E0",
      dark: "#A67C00",
    },
    success: {
      main: "#00FF00",
      light: "#B2FFB2",
      dark: "#00A600",
    },
    background: {
      default: "#F5F5F5",
      paper: "#fff",
    },
  },
};

export const themeMUI = createTheme({
  palette: {
    primary: {
      dark: "#148776",
      main: "#23B09B",
      light: "#13494a",
      contrastText: "#FFFFFF"
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    // text: {
    //   primary: '#F6F7F7',
    //   secondary: '#9e9e9e',
    //   disabled: '#f5f5f5',
    // },
    secondary: {
      main: "#FF702A",
      light: "#FF9962",
    },
  },
});
