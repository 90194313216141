import styled from 'styled-components';
import backgroundImg from '../../assets/signin_background.svg';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 70%;
  height: 100%;
  background: url(${backgroundImg}) no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 5rem;
  }
`;

export const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    padding: 2rem;
  }

  
`;
