import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../../assets/main_logo.svg";
import { Container, ImageContainer, LoginContainer } from "./styles";
import { api } from "../../services/api";
import { ICallPanel, IClinic } from "../../models";
import { usePanel } from "../../contexts/PanelContext";

export default function Login() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allPanel, setAllPanel] = React.useState<ICallPanel[]>([]);
  const [selectedPanel, setSelectedPanel] = React.useState<ICallPanel | null>(
    null
  );
  const [allClinics, setAllClinics] = React.useState<IClinic[]>([]);
  const [clinicId, setClinicId] = React.useState<string>("");
  const [callPanelType, setCallPanelType] = React.useState<string>("");
  const [clinicIdError, setClinicIdError] = React.useState<string>("");

  const navigate = useNavigate();
  const { handleRegisterPanel } = usePanel();

  const getAllPanel = async (idclinica: string, tipoPainel: string) => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/users-patients/call-panel?idclinica=${idclinica}&type=${tipoPainel}`
      );
      setAllPanel(res.data.data.call_panels);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getAllClinics = async () => {
    try {
      setIsLoading(true);
      const res = await api.get("/call-panel/clinics");
      setAllClinics(res.data.data.clinics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (clinicId !== "" && callPanelType !== "") {
      getAllPanel(clinicId, callPanelType);
    }
  }, [clinicId, callPanelType]);

  useEffect(() => {
    getAllClinics();
  }, []);

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const res = await handleRegisterPanel(selectedPanel);
      if (res) {
         document.body.requestFullscreen();
        navigate("/", {
          replace: true,
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <Container>
      {isLoading && (
        <LinearProgress color="secondary" variant={"indeterminate"} />
      )}
      <ImageContainer>
        <Typography component="h1" sx={{
          fontWeight: 600,
          color: theme => theme.palette.background.default,
        }}>Painel de chamada</Typography>
      </ImageContainer>

      <LoginContainer>
        <img src={logo} alt="Logo" />
        <form onSubmit={handleLogin}>
          <Grid
            container
            mt={3}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                loading={isLoading}
                id="clinics"
                options={allClinics}
                onChange={(event: any, newValue: IClinic | null) => {
                  setClinicId(newValue === null ? "" : newValue.idclinica);
                  setClinicIdError("");
                }}
                getOptionLabel={(e) => e.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma clínica"
                    error={!!clinicIdError}
                    helperText={clinicIdError}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                loading={isLoading}
                id="type"
                value={callPanelType}
                options={["CONSULTÓRIO", "GUICHÊ"]}
                onChange={(event: any, newValue: any) => {
                  setCallPanelType(
                    newValue === null ? "CONSULTÓRIO" : newValue
                  );
                }}
                getOptionLabel={(e) => e}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione um tipo" />
                )}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Autocomplete
                fullWidth
                loading={isLoading}
                disabled={clinicId === "" || callPanelType === ""}
                id="clinics"
                value={selectedPanel}
                options={allPanel}
                onChange={(event: any, newValue: ICallPanel | null) => {
                  setSelectedPanel(newValue);
                }}
                getOptionLabel={(e) =>
                  `${e.nome} ${
                    e.setor && e.bloco
                      ? `- ${e.setor?.nome} - ${e.bloco?.nome}`
                      : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Selecione um painel" />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <Button
                type="submit"
                disabled={selectedPanel === null}
                fullWidth
                size="large"
                variant="contained"
              >
                Entrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoginContainer>
    </Container>
  );
}
